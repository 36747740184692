.Block10 {
  padding: 0;
  padding-top: 150px;
  padding-bottom: 150px;

  .Block10__button {
    @media screen and(max-width: 1200px) {
      margin-top: -30px !important;
    }
  }

  .Block10__buttonCol {
    @media screen and(max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .IconButton {
    @media screen and(max-width: 576px) {
      width: 280px !important;
    }
  }

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 130%;
    color: #ffffff;
    padding-bottom: 80px;

    @media screen and(max-width: 576px) {
      font-size: 26px;
      padding-bottom: 50px;
    }
  }

  .Block10__separator {
    width: 100%;
    height: 30px;
  }

  .Block10__tasks {
    h3 {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 145%;
      color: #ffffff;
      padding: 0;
      margin-bottom: -30px;
      padding-top: 30px;

      @media screen and(max-width: 576px) {
        font-size: 18px;
      }
    }
  }

  .Block10__buttonCol {
    p {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 145%;
      color: #ffffff;
    }

    @media screen and(max-width: 992px) {
      padding-bottom: 30px;
    }
  }

  .Block10__caseCont {
    padding: 0;
    padding-right: 20px;
    padding-bottom: 30px;

    &:nth-child(1) {
      @media screen and(max-width: 992px) {
        width: 100%;
      }
    }

    @media screen and(max-width: 1200px) {
      padding: 0 !important;
      padding-bottom: 30px !important;
    }

    &.Block10__caseContSpecial {
      background-color: #ffd467;
      padding: 30px !important;
      margin-bottom: 30px;

      .CaseItemCard__title {
        color: #000000;
      }
      .CaseItemCard__text {
        color: #000000;
      }
    }
  }

  .Block10__case {
    padding: 0;
    padding-bottom: 70px;
  }

  .Block10__caseRow {
    padding-top: 70px;
    padding-bottom: 60px;
  }

  @media screen and(max-width: 992px) {
    padding-top: 0px;
  }

  @media screen and(max-width: 576px) {
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
