.Block1 {
  padding: 0;
  height: 975px;

  .Block1__Left {
    padding: 0;
  }

  background-repeat: no-repeat;
  background-size: 100% 975px;
  @media screen and(max-width: 768px) {
    background-size: cover;
    background-position: center bottom;
    height: 1046px;
  }

  @media screen and(max-width: 576px) {
    background-size: cover;
    background-position: center bottom;
  }

  .Miraclum__dotMessageTitle {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .Miraclum__dotMessageContent {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  .Block1__Content {
    padding: 0;
    padding-top: 270px;

    .Block1__blurCard {
      width: 100%;
      height: 500px;
      padding: 70px;

      background: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(15px);
      border-radius: 3px;

      p {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 145%;
        color: #ffffff;
        padding-bottom: 30px;
      }

      h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 130%;
        color: #ffffff;
        padding-bottom: 30px;
      }

      @media screen and(max-width: 1200px) {
        p {
          font-size: 16px;
        }

        h1 {
          font-size: 40px;
        }
      }

      @media screen and(max-width: 992px) {
        padding: 30px;
        p {
          font-size: 16px;
        }

        h1 {
          font-size: 32px;
        }
      }

      @media screen and(max-width: 576px) {
        padding: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        p {
          font-size: 16px;
        }

        h1 {
          font-size: 28px;
        }
        margin-top: -30px;
        height: auto;

        .IconButton {
          width: 280px !important;
        }
      }
    }

    .Block1__circleDots {
      padding: 0;

      .Block1__circleDot1,
      .Block1__circleDot2,
      .Block1__circleDot3 {
        position: absolute;
      }

      .Block1__circleDot1 {
        margin-top: 230px;
        margin-left: 530px;
      }
      .Block1__circleDot2 {
        margin-top: 310px;
        margin-left: 300px;
      }
      .Block1__circleDot3 {
        margin-top: 360px;
        margin-left: 70px;
      }

      @media screen and(max-width: 1800px) {
        .Block1__circleDot1 {
          margin-top: 230px;
          margin-left: 480px;
        }
        .Block1__circleDot2 {
          margin-top: 310px;
          margin-left: 270px;
        }
        .Block1__circleDot3 {
          margin-top: 360px;
          margin-left: 60px;
        }
      }
      @media screen and(max-width: 1600px) {
        .Block1__circleDot1 {
          margin-top: 230px;
          margin-left: 430px;
        }
        .Block1__circleDot2 {
          margin-top: 315px;
          margin-left: 230px;
        }
        .Block1__circleDot3 {
          margin-top: 365px;
          margin-left: 10px;
        }
      }
      @media screen and(max-width: 1400px) {
        .Block1__circleDot1 {
          margin-top: 210px;
          margin-left: 430px;
        }
        .Block1__circleDot2 {
          margin-top: 300px;
          margin-left: 220px;
        }
        .Block1__circleDot3 {
          margin-top: 365px;
          margin-left: 10px;
        }
      }

      @media screen and(max-width: 1200px) {
        .Block1__circleDot1 {
          margin-top: 210px;
          margin-left: 370px;
        }
        .Block1__circleDot2 {
          margin-top: 300px;
          margin-left: 180px;
        }
        .Block1__circleDot3 {
          margin-top: 365px;
          margin-left: 10px;
        }
      }

      @media screen and(max-width: 1000px) {
        .Block1__circleDot1 {
          margin-top: 210px;
          margin-left: 310px;
        }
        .Block1__circleDot2 {
          margin-top: 300px;
          margin-left: 150px;
        }
        .Block1__circleDot3 {
          margin-top: 365px;
          margin-left: 10px;
        }
      }

      @media screen and(max-width: 900px) {
        .Block1__circleDot1 {
          margin-top: 210px;
          margin-left: 260px;
        }
        .Block1__circleDot2 {
          margin-top: 300px;
          margin-left: 120px;
        }
        .Block1__circleDot3 {
          margin-top: 365px;
          margin-left: -20px;
        }
      }

      @media screen and(max-width: 768px) {
        .Block1__circleDot1,
        .Block1__circleDot2,
        .Block1__circleDot3 {
          position: relative;
          display: flex;
          justify-content: center;
          padding-top: 30px;
        }

        .Block1__circleDot1 {
          margin-top: 0px;
          margin-left: 0px;
        }
        .Block1__circleDot2 {
          margin-top: 0px;
          margin-left: 0px;
        }
        .Block1__circleDot3 {
          margin-top: 0px;
          margin-left: 0px;
        }
      }

      @media screen and(max-width: 576px) {
        .Block1__circleDot1,
        .Block1__circleDot2,
        .Block1__circleDot3 {
          position: relative;
          display: flex;
          justify-content: center;
          padding-top: 50px;
        }
      }
    }
  }
}
