.ModalWindow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;

  font-family: 'Montserrat';
  font-style: normal;
  line-height: 180%;

  .ModalWindow__container {
    position: fixed;
    top: 50px;
    bottom: 50px;
    left: 50px;
    right: 50px;
    background-color: #1C1C1C;
    border-radius: 3px;
    animation-duration: .5s;
  }

  .ModalWindow__header {
    height: 10%;
    padding: 20px;
  }

  .ModalWindow__headerTitle {
    font-size: 24px;
    font-weight: 600;
    padding: 20px;
  }

  .ModalWindow__body {
    padding: 30px;
    padding-top: 120px;
    padding-bottom: 20px;
    height: 90%;
    overflow-y: scroll;
    align-items: start !important;

    @media screen and(max-width: 576px) {
      padding: 10px;
      padding-top: 80px;
      padding-bottom: 20px;
    }

    @media screen and(max-width: 400px) {
      padding: 10px;
      padding-top: 0;
      padding-bottom: 0;
    }

    /* полоса прокрутки (скроллбар) */
    &::-webkit-scrollbar {
      width: 5px; /* ширина для вертикального скролла */
      height: 5px; /* высота для горизонтального скролла */
    }

    /* ползунок скроллбара */
    &::-webkit-scrollbar-thumb {
      background-color: #6473fb;
      // color: #5e5e5e;
    }
  }

  .ModalWindow__close {
    padding: 20px;
    cursor: pointer;

    svg {
      transition: all ease-in-out 0.2s;
    }

    &:hover {
      svg {
        fill: #6473fb;
      }
    }
  }

  @media screen and(max-width:576px) {
    .ModalWindow__container {
      top: 10%;
      bottom: 10%;
      left: 10px;
      right: 10px;
    }
  }

  @media screen and(max-width:400px) {
    .ModalWindow__container {
      top: 20%;
      bottom: 20%;
    }
  }
}
