.SolutionCard {
  background-color: none;
  border-top: 2px solid #333333;
  border-bottom: 2px solid #333333;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    border-top: 2px solid #6473fb;
    border-bottom: 2px solid #6473fb;
  }

  .SolutionCard__Row1 {
    padding: 20px;
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    z-index: 20;

    @media screen and(max-width:576px) {
      // padding: 20px;
    }
  }

  .SolutionCard__title {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 145%;
    color: #ffffff;

    @media screen and(max-width:576px) {
      font-size: 16px;
    }
  }

  .SolutionCard__icon {
    background-color: #6473fb;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }
  }
}

.SolutionCard__dd {
  position: relative;
  z-index: 10;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #ffffff;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;

  animation-duration: 0.5s;

  // background: rgba(51, 51, 51, 0.9);
  // backdrop-filter: blur(15px);
  // box-shadow: 0px 0px 10px rgba(100, 115, 251, 0.5);
  background: #333333;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 3px;

  @media screen and(max-width:576px) {
    padding: 20px;
    font-size: 12px;
  }
}
