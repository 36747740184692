.Block9 {
  background-color: #222222;

  .Block9__Cont {
    padding: 0;
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .Block9__title {
    h1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 130%;
      color: #ffffff;
      padding-bottom: 80px;
    }
  }

  .Block9__cardCont {
    padding: 0;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .Block9__right {
    .Block9__fadeImg {
      animation-duration: 1s;
    }

    img {
      // animation-duration: 1s;
      // margin-top: -100px;
      // margin-right: -130px;
      // -webkit-transform: scale(1.3);
      // transform: scale(1.3);

      @media screen and(max-width: 1400px) {
        margin: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}
