.Block8 {
  padding: 0;
  padding-top: 150px;
  padding-bottom: 150px;

  @media screen and(max-width: 576px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 130%;
    color: #ffffff;
  }

  .Block8__title {
    padding-top: 40px;
    width: 400px;

    .HorLineCont {
      position: absolute;
      width: 300px;
      margin-top: 10px;
    }
  }

  .Block8__cardCont {
    padding: 10px;
  }

  .Block8__titleMob {
    padding: 20px;

    h1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 900;
      font-size: 26px;
      line-height: 130%;
      color: #ffffff;
    }

    .HorLine {
      padding-top: 20px !important;
      padding-bottom: 30px !important;
    }
  }

  .CommandCard {
    @media screen and(max-width: 576px) {
      width: 300px;
    }
  }
}
