.MiraclumLogo {
  .MainHeader__logo {
    padding-right: 20px;
  }

  .MainHeader__logoTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
  }

  .MainHeader__logoDescr {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: 0.15em;
    color: #ffffff;
  }
}
