.MainHeader {
  padding: 0;
  margin-bottom: -226px;
  position: relative;
  z-index: 10;

  .Sanddwich {
    display: flex;
  }

  .MainHeader__Cont {
    padding: 0;
    padding-top: 50px;

    .MainHeader__Row1 {
      // width: 100%;
      padding-bottom: 50px;
    }

    a {
      text-decoration: none;
    }

    .MainHeader__burger {
      padding-right: 20px;
      cursor: pointer;
    }

    @media screen and(max-width:576px) {
      .MainHeader__logo {
        padding-left: 10px;
        padding-right: 10px;
      }

      .MainHeader__logoTitle {
        font-size: 20px;
      }
  
      .MainHeader__logoDescr {
        font-size: 13px;
      }
    }

    .MainHeader__phoneCont {
      a {
        text-decoration: none;
        color: #FFD467;
        transition: .5s;

        &:hover {
          color: #6473FB;
        }
      }
    }

    .MainHeader__phone {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 145%;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      text-align: right;
      color: #ffffff;
    }

    .MainHeader__phoneNumber {
      padding: 10px;
      margin-top: -30px !important;
    }

    .MainHeader__phoneTitle {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 130%;
      text-align: right;
      color: #ffffff;
      cursor: pointer;
    }

    .MainHeader__navbarEl {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #ffffff;
      padding-right: 50px;
      padding-bottom: 20px;
      cursor: pointer;

      &:hover {
        color: #FFD467;
      }
    }
  }
}
