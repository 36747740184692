.Block7 {
  background-color: #222222;
  padding-top: 150px;
  padding-bottom: 100px;
  margin-top: 130px;

  .Block7__Cont {
    h1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 900;
      font-size: 42px;
      line-height: 130%;
      color: #ffffff;
      padding-bottom: 50px;

      @media screen and(max-width: 576px) {
        font-size: 26px;
      }
    }

    .Block7__partner {
      padding: 10px;
      margin-bottom: 80px;

      @media screen and(max-width: 576px) {
        margin-bottom: 20px;
      }
    }
  }
}
