.Block12 {
  padding: 0;
  padding-top: 150px;
  padding-bottom: 150px;
  background-color: #303030;

  @media screen and(max-width:576px) {
    padding: 20px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .Block12__Cont {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 130%;
    color: #ffffff;

    h1 {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 900;
      font-size: 42px;
      line-height: 130%;
      color: #ffffff;
      padding-bottom: 70px;

      @media screen and(max-width:576px) {
        font-size: 26px;
      }
    }

    h4 {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 145%;
      color: #ffffff;
      padding-bottom: 50px;

      @media screen and(max-width:576px) {
        font-size: 16px;
      }
    }

    p {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 145%;
      color: #ffffff;
      padding-top: 40px;

      @media screen and(max-width:576px) {
        text-align: center;
      }

      a {
        text-decoration: none;
        color: #5b68df;
      }
    }
  }

  .LoaderHorizontal {
    .lds-ellipsis {
      div {
        background: #ffc83a;
      }
    }
  }

  .IconButton {
    .IconButton__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 145%;
      text-align: center;

      color: #1c1c1c;
    }

    @media screen and(max-width:576px) {
      display: flex;
      width: 100% !important;
    }
  }

  .hvr-sweep-to-right:before {
    background: #ffc83a;
  }

  .Block12__formFieldCont {
    padding-right: 30px;
    padding-bottom: 30px;

    .KisModalForm__imgCont {
      img {
        position: absolute;
        margin-top: -57px;
        margin-left: 15px;
      }
    }
    
    .Block12__inputError {
      font-family: 'Raleway';
      font-style: normal;
      font-weight: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #f17878;
      padding-top: 10px;
      padding-left: 5px;
    }

    .Block12__input {
      width: 300px;
      height: 80px;
      background: #efefef;
      border-radius: 3px;

      font-family: 'Raleway';
      font-style: normal;
      font-weight: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 145%;
      color: #5a5a5a;
      padding: 25px;

      &.phone {
        padding-left: 70px;
      }

      &::placeholder {
        font-size: 20px;
        font-weight: 400;
        line-height: 145%;
        color: #919191;
        // padding: 25px;
      }

      @media screen and(max-width:576px) {
        width: 100%;
      }
    }

    @media screen and(max-width:1200px) {
      padding-right: 20px !important;
      padding-bottom: 20px !important;
    }

    @media screen and(max-width:576px) {
      width: 100%;
      padding-right: 0 !important;
    }
  }
}
