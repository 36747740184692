.TaskDropDownCard {
  padding: 0;

  .TaskDropDownCard__ddImg {
    img {
      width: 28px;
      height: 28px;
    }
  }

  .TaskDropDownCard__Row {
    padding: 20px;
    padding-left: 0;
    cursor: pointer;
    border-top: 1px solid #333333;
    border-bottom: 1px solid #333333;
    transition: 0.5s;

    &:hover {
      border-top: 1px solid #5965cb;
      border-bottom: 1px solid #5965cb;
    }

    .TaskDropDownCard__name {
      .TaskDropDownCard__nameImg {
        padding-right: 20px;
        margin-top: -2px;

        @media screen and(max-width: 576px) {
          padding-left: 20px;
        }
      }

      .TaskDropDownCard__nameTitle {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 145%;
        color: #ffffff;

        @media screen and(max-width: 576px) {
          font-size: 18px;
        }
      }
    }
  }
}

.TaskDropDownCard__dd {
  padding: 0;
  animation-duration: .5s;

  .TaskDropDownCard__taskList {
    padding: 0;
    padding-top: 30px;

    @media screen and(max-width: 576px) {
      margin: 0;
    }

    .TaskDropDownCard__task {
      padding: 20px;

      font-family: 'Raleway';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 145%;
      color: #d9d9d9;

      @media screen and(max-width: 576px) {
        padding: 0px;
        padding-bottom: 20px;
        font-size: 16px;
      }
    }
  }
}
