.Block2 {
  padding: 0;
  padding-top: 150px;
  padding-bottom: 150px;

  .MobileHorLineCont {
    padding-left: 20px;
    padding-right: 20px;
    .MobileHorLine {
      height: 2px;
      background-color: #ffd467;
    }
  }

  .hvr-grow:hover,
  .hvr-grow:focus,
  .hvr-grow:active {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 130%;
    color: #ffffff;
    text-align: right;
    padding-bottom: 50px;

    @media screen and(max-width: 768px) {
      padding-bottom: 20px;
    }

    @media screen and(max-width: 576px) {
      font-size: 26px;
      text-align: left;
      padding-left: 20px;
    }
  }

  .Block2__Row {
    margin: 0;
  }

  .Block2__Left {
    padding: 0;
    border-bottom: 2px solid #ffd467;
    img {
      padding-left: 40px;
      // padding-bottom: 90px;
    }

    .w370 {
      display: block;
    }

    .w0 {
      display: none;
    }

    @media screen and(max-width: 768px) {
      border-bottom: none;
      padding-left: 20px;
      // padding-right: 20px;

      img {
        padding-top: 40px;
        padding-bottom: 0px;
      }
    }

    @media screen and(max-width: 370px) {
      .w370 {
        display: none;
      }

      .w0 {
        display: block;
      }
    }
  }

  .Block2__Right {
    padding: 0;
  }

  .Block2__list {
    border-top: 2px solid #ffd467;
    padding-top: 100px;
    .Block2__listEl {
      width: 100%;
      padding: 20px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 135%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      background-color: #333333;
      span {
        color: #5965cb;
        padding-right: 5px;
      }

      &:nth-child(2n-1) {
        background-color: #3c3c3c;
      }

      @media screen and(max-width: 576px) {
        font-size: 16px;
      }
    }

    @media screen and(max-width: 1200px) {
      padding-top: 90px;
    }

    @media screen and(max-width: 768px) {
      border-top: none;
      padding-top: 40px;
    }
  }
}
