.CaseDropDownCard {
  padding: 0;
  .CaseDropDownCard__Row {
    padding: 20px;
    background-color: #333333;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background-color: #2c2c2c;
      box-shadow: 2px 2px 2px rgba(100, 115, 251, 0.6);
    }

    .CaseDropDownCard__name {
      .CaseDropDownCard__nameImg {
        padding-right: 20px;
        margin-top: -2px;

      }

      .CaseDropDownCard__nameTitle {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        color: #ffffff;
      }
    }
  }

  .CaseDropDownCard__ddCont {
    position: relative;
    .CaseDropDownCard__dd {
      padding: 0;
      background-color: #333333;
      position: absolute;
      z-index: 10;
      margin-top: 10px;

      .CaseDropDownCard__caseItem {
        padding: 20px;
        cursor: pointer;

        &:hover {
          background-color: #2c2c2c;
        }

        .CaseDropDownCard__caseItemImg {
          padding-right: 20px;
          margin-top: -2px;
        }

        .CaseDropDownCard__caseItemTitle {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 145%;
          color: #ffffff;
        }
      }
    }
  }
}
