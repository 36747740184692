.ToastComponent {
  .ToastComponent__sendRequestMessage {
    position: fixed;
    top: 20px; left: 20px;
    width: 400px;
    color: #ffffff;
    cursor: pointer;
    animation-duration: 1s;
    z-index: 2000;
    font-size: 16px;

    .ToastComponent__sendRequestMessageMsg {
      padding: 20px;
      padding-top: 0;
    }

    img {
      width: 20px;
      margin: 10px;
    }
  }

  .ToastComponent__sendRequestMessageCloseBtn {
    padding: 5px;
  }

  .successMessage {
    background-color: rgba(0, 158, 79, .8);
  }

  .errorMessage {
    background-color: rgba(134, 0, 0, .8);
  }
}